
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ReportingFilter from '@/components/pages/reporting/ReportingFilter.vue'
import SubAccountsTable from '@/components/pages/reporting/subAccounts/SubAccountsTable.vue'
import { getTableData } from '@/services/tableService'
import { formatNumber, numberToPrice } from '@/services/utils'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    SubAccountsTable,
    ReportingFilter,
    PageContent,
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'User', value: 'user-slot' },
      { text: 'Email', value: 'email' },
      { text: 'Sent', value: 'sent', format: (val: string) => formatNumber(+val) },
      { text: 'Received', value: 'received', format: (val: string) => formatNumber(+val) },
      { text: 'Delivery rate', value: 'deliveryRate' },
      { text: 'Reply rate', value: 'replyRate' },
      { text: 'Costs', value: 'costs', format: (val: string) => numberToPrice(+val) },
    ])
    const tableItems = ref(getTableData('reportingSubAccounts'))

    return {
      tableHeaders,
      tableItems,
    }
  },
})
