
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import { numberToPrice } from '@/services/utils'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmPerson,
    TmTable,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { numberToPrice }
  },
})
