import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items
  }, {
    "body-cell-user-slot": _withCtx((props) => [
      _createVNode(_component_tm_person, {
        name: props.row.user.fullName,
        "avatar-color": props.row.user.avatarColor,
        "avatar-url": props.row.user.avatar,
        "avatar-size": "small"
      }, null, 8, ["name", "avatar-color", "avatar-url"])
    ]),
    _: 1
  }, 8, ["headers", "items"]))
}